import Appsignal from "@appsignal/javascript"
import { plugin } from "@appsignal/plugin-path-decorator"

window.appsignal = new Appsignal({
  key: process.env.APPSIGNAL_FRONTEND_PUSH_KEY,
})
appsignal.use(plugin())

appsignal.addDecorator((span) => {
  var user_id = (document.cookie.match(/^(?:.*;)?\s*re_id\s*=\s*([^;]+)(?:.*)?$/) || [, null])[1]
  var session_id = (document.cookie.match(/^(?:.*;)?\s*op_id\s*=\s*([^;]+)(?:.*)?$/) || [, null])[1]
  var product_id = (document.cookie.match(/^(?:.*;)?\s*tu_id\s*=\s*([^;]+)(?:.*)?$/) || [, null])[1]
  return span.setTags({ user_id: user_id, session_id: session_id, product_id: product_id })
})
